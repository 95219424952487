import { gsap } from "gsap";
import React, { useEffect, useRef, useState } from "react";
import beyondImg from "./images/img-beyond.webp";
import "./beyond.css";

export default function Beyond() {
  let btnRef = useRef(null);
  useEffect(() => {
    gsap.set(btnRef, {
      x: -200
    });
  });
  return (
    <div className="beyond-wrapper">
      <section className="section1 beyond">
        <div className="beyond-container">
          <div className="beyond-header">
            <div className="beyond-header-child-1">
              A tömegen és a város fényén túl megtaláltam az enyémet
            </div>
            <div className="beyond-header-child-2">
              URB<span>ANIMA</span>
            </div>
            <div className="beyond-header-child-3">Van neked? </div>
            {/* <div className="beyond-button
             ref={(el) => (btnRef = el)}>
              <a href="https://www.teilor.ro/urbanima.html">
                DISCOVER THE <br />
                <span>COLLECTION</span>
              </a>
            </div> */}
            <div
              className="bridal-collection-button-2"
              ref={(el) => (btnRef = el)}
            >
              <div className="bridal-button-border"></div>
              <div className="bridal-button-text">
                <a href="https://urbanima.teilor.hu/">
                  FEDEZD FEL A <br />
                  <span>KOLLEKCIÓT</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="beyond-image">
          <img src={beyondImg}></img>
        </div>
      </section>
    </div>
  );
}
