import "./styles.css";
import insta1 from "./images/footer/insta1.png";
import insta2 from "./images/footer/insta2.png";
import insta3 from "./images/footer/insta3.png";
import insta4 from "./images/footer/insta4.png";
import insta5 from "./images/footer/insta5.png";
import insta6 from "./images/footer/insta6.png";
import instaIcon from "./images/footer/instagram1.svg";
import call from "./images/footer/call.png";
import facebook from "./images/footer/facebook.png";
import mail from "./images/footer/mail.png";
import footer from "./images/footer/footer.svg";
import youtube from "./images/footer/youtube.svg";
import instagram from "./images/footer/instagram.png";
export default function Footer() {
  return (
    <div>
      <section className="section1 footer-down">
        <div className="footer1">
          <div className="footer-complete">
            <div className="footer-column-desktop">
              <p className="title-footer-down">ABOUT TEILOR</p>
              <ul>
                <li>
                  <a href="/" target="/">
                    Teilor'S Universe
                  </a>
                </li>
                <li>
                  <a href="/" target="/">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="/" target="/">
                    Careers
                  </a>
                </li>
                <li>
                  <a href="/" target="/">
                    GDPR policy
                  </a>
                </li>
                <li>
                  <a href="/" target="/">
                    Terms & conditions
                  </a>
                </li>
                <li>
                  <a href="/" target="/">
                    Video surveillance policy
                  </a>
                </li>
                <li>
                  <a href="/" target="/">
                    Cookies policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-column-desktop">
              <p className="title-footer-down">CLIENT CARE</p>
              <ul>
                <li>
                  <a href="/" target="/">
                    Book an appointment
                  </a>
                </li>
                <li>
                  <a href="/" target="/">
                    Payment
                  </a>
                </li>
                <li>
                  <a href="/" target="/">
                    Track your order{" "}
                  </a>
                </li>
                <li>
                  <a href="/" target="/">
                    Shipping and return
                  </a>
                </li>
                <li>
                  <a href="/" target="/">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-column-desktop">
              <p className="title-footer-down">
                <span className="title-footer-content-1">GUARANTEE &</span>
                <span> SERVICE</span>
              </p>
              <ul>
                <li>
                  <a href="/" target="/">
                    Product Care & Repair
                  </a>
                </li>
                <li>
                  <a href="/" target="/">
                    Service Store near you
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-column-desktop stay-in-touch">
              <p className="title-footer-down">Stay in touch with us</p>
              <ul className="footer-utils">
                <li>
                  <a href={"https://www.facebook.com/b.teilor"}>
                    {" "}
                    <img className="facebook" src={facebook} />{" "}
                  </a>
                </li>
                <li>
                  <a
                    href={
                      "https://www.instagram.com/teilor_finejewellery/?hl=ro"
                    }
                  >
                    {" "}
                    <img className="instagram" src={instagram} />{" "}
                  </a>
                </li>
                <li>
                  <a href={"https://www.youtube.com"}>
                    {" "}
                    <img className="youtube" src={youtube} />{" "}
                  </a>
                </li>
              </ul>
              <ul className="footer-contacts">
                <li className="mail-contact">
                  <img className="mail" src={mail} />
                  <p>contact@teilor.com</p>
                </li>
                <li className="phone-contact">
                  <img className="call" src={call} />
                  <p>+40 736.555.999</p>
                </li>
              </ul>
            </div>
            <div
              className="bottom-logos"
              style={{ backgroundImage: `url(${footer})` }}
            >
              <a
                className="teilor-main-link-bottom"
                href={"https://www.teilor.com/"}
              ></a>
            </div>
            <div className="copy">© TEILOR SRL 2021</div>
          </div>
        </div>
      </section>
    </div>
  );
}
