import React, { useEffect, useRef } from "react";
import "./logoAnim.css";
import { gsap } from "gsap";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from "react-scroll";

export default function LogoAnim() {
  let logo1Ref = useRef(null);
  let loaderRef = useRef(null);
  let leftSideRef = useRef(null);
  let rightSideRef = useRef(null);
  let maskLeftRef = useRef(null);
  let maskRightRef = useRef(null);

  function enableScroll() {
    document
      .getElementById("body-main")
      .setAttribute("style", "overflow-y: unset !important; ");
    document
      .getElementById("html-main")
      .setAttribute("style", "overflow-y: unset !important;");
  }
  //LOGO ANIMATION
  useEffect(() => {
    // gsap.set(loaderRef)
    gsap.to(loaderRef, { opacity: 1, duration: 0.2 });
    gsap.from(leftSideRef, {
      opacity: 0,
      x: 800,
      duration: 2,
      delay: 1.5
    });
    gsap.from(rightSideRef, {
      opacity: 0,
      x: -800,
      duration: 2,
      delay: 1.5
    });

    gsap.from(logo1Ref, { duration: 2, opacity: 0, delay: 0.4 });
    gsap.to(logo1Ref, {
      duration: 1,
      opacity: 0,
      delay: 3,
      onComplete: () => {
        scroll.scrollToTop();
      }
    });

    gsap.from(maskLeftRef, { duration: 2, x: 1000, delay: 1.2 });
    gsap.to(maskLeftRef, { duration: 1, opacity: 0, delay: 3.7 });

    gsap.from(maskRightRef, { duration: 2, x: -1000, delay: 1.1 });
    gsap.to(maskRightRef, { duration: 1, opacity: 0, delay: 3.7 });

    gsap.from(".loader", {
      duration: 2,
      y: "40vh",
      delay: 3.7,
      onComplete: () => {
        enableScroll();
      }
    });
    gsap.to(".loader", {
      duration: 1,
      opacity: 0,
      delay: 3.7
    });
  }, []);

  return (
    <div className="logoAnim header">
      <div className="loader " ref={(el) => (loaderRef = el)}>
        {" "}
        <svg
          className="teilor-logo"
          width="811"
          height="256"
          viewBox="0 0 811 256"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            className="logo-type"
            ref={(el) => (logo1Ref = el)}
            clip-path="url(#clip0)"
          >
            <path
              d="M66.7404 70.1047V83.7987H69.9788C69.9788 78.9914 73.8823 75.0878 78.6896 75.0878H102.588V167.192C102.588 172 98.6845 175.903 93.8772 175.903V179.141H122.495V175.903C117.688 175.903 113.784 172 113.784 167.192V75.0878H137.683C142.49 75.0878 146.394 78.9914 146.394 83.7987H149.632V70.1047H66.7404H66.7404Z"
              fill="#010101"
            />
            <path
              d="M339.652 70.1047V73.3431C344.46 73.3431 348.363 77.2467 348.363 82.054V167.192C348.363 172 344.46 175.903 339.652 175.903V179.141H368.27V175.903C363.463 175.903 359.572 172.012 359.559 167.217V82.054C359.559 77.2467 363.463 73.3431 368.27 73.3431V70.1047H339.652Z"
              fill="#010101"
            />
            <path
              d="M604.719 70.1047C574.608 70.1047 550.207 94.5053 550.207 124.617C550.207 154.728 574.608 179.129 604.719 179.129C634.831 179.129 659.231 154.728 659.231 124.617C659.231 94.5052 634.818 70.1047 604.719 70.1047ZM604.694 174.158C581.888 174.158 563.412 151.98 563.412 124.629C563.412 97.2667 581.9 75.1003 604.694 75.1003C627.488 75.1003 645.977 97.2792 645.977 124.629C645.977 151.98 627.488 174.158 604.694 174.158Z"
              fill="#010101"
            />
            <path
              d="M274.484 159.473H272.25C270.04 167.92 262.346 174.158 253.209 174.158H229.436V127.115H258.066C262.873 127.115 266.777 131.018 266.777 135.826H270.015V113.421H266.777C266.777 118.228 262.873 122.132 258.066 122.132H229.436V75.0878H258.066C262.873 75.0878 266.777 78.9914 266.777 83.7987H270.015V70.1047H209.529V73.3431C214.336 73.3431 218.24 77.2467 218.24 82.054V167.18C218.24 171.987 214.336 175.891 209.529 175.891V179.129H270.015L274.484 159.473Z"
              fill="#010101"
            />
            <path
              d="M499.398 159.473H497.164C494.955 167.92 487.26 174.158 478.123 174.158H454.35V82.054C454.35 77.2467 458.253 73.3431 463.061 73.3431V70.1047H434.443V73.3431C439.25 73.3431 443.154 77.2467 443.154 82.054V167.18C443.154 171.987 439.25 175.891 434.443 175.891V179.129H494.93L499.398 159.473Z"
              fill="#010101"
            />
            <path
              d="M780.004 164.356L755.629 125.935C767.415 122.445 776.012 111.538 776.012 98.6097C776.012 82.8698 763.247 70.1047 747.508 70.1047H707.581V73.3431C712.388 73.3431 716.292 77.2467 716.292 82.054V167.18C716.292 171.987 712.388 175.891 707.581 175.891V179.129H736.198V175.891C731.391 175.891 727.488 171.987 727.488 167.18V127.102H744.457L772.636 171.523C772.937 171.974 773.113 172.502 773.113 173.079C773.113 174.623 771.858 175.878 770.314 175.878V179.116H799.66V175.878C791.414 175.376 784.234 170.908 780.004 164.356ZM727.488 75.0878H741.307C754.298 75.0878 764.829 85.6187 764.829 98.6097C764.829 111.601 754.298 122.132 741.32 122.132H727.488V75.0878Z"
              fill="#010101"
            />
          </g>
          <rect
            className="mask-left"
            ref={(el) => (maskLeftRef = el)}
            x="29"
            y="23"
            width="782"
            height="210"
            fill="url(#paint0_linear)"
          />
          <rect
            className="mask-right"
            ref={(el) => (maskRightRef = el)}
            width="782"
            height="210"
            transform="matrix(-1 0 0 1 782 23)"
            fill="url(#paint1_linear)"
          />
          <path
            className="left-side"
            ref={(el) => (leftSideRef = el)}
            d="M404.652 208.992H425.704C426.929 209.04 427.525 208.661 427.509 207.863C427.549 207.363 427.243 207.056 426.607 207.008C426.036 206.996 425.467 206.937 424.907 206.83C424.302 206.717 423.738 206.588 423.215 206.435C422.639 206.242 422.123 205.903 421.718 205.451C421.312 204.998 421.031 204.448 420.902 203.854C420.392 202.141 420.068 200.378 419.936 198.595C419.667 195.547 419.503 191.352 419.444 186.013C419.385 180.674 419.361 174.568 419.372 167.696V40.4284C424.947 40.5172 474.286 40.6139 479.861 40.7027C483.294 40.7403 485.864 41.0898 487.572 41.7512C489.249 42.3857 490.615 43.6462 491.383 45.2678C491.987 46.4454 492.422 47.3649 492.688 48.0101C492.954 48.6554 493.155 49.2119 493.308 49.6878C493.622 50.7282 494.001 51.2041 494.436 51.1073C494.871 51.0105 494.984 50.6798 495 49.8249C494.909 48.6634 494.522 46.2222 493.84 42.5013C493.163 38.775 492.72 36.5085 492.519 35.6859C492.398 35.2019 492.237 34.6535 492.035 34.0243C491.834 33.3952 491.608 33.0565 491.383 33C490.812 33.1472 490.268 33.384 489.771 33.7017C488.726 34.1821 487.585 34.414 486.436 34.3792C484.712 34.5889 482.987 34.7664 481.255 34.8874C479.523 35.0083 478.266 35.0809 477.509 35.089H406.851C405.973 35.089 405.619 36.5005 406.328 37.1296L408.551 39.0976C409.044 39.5653 409.433 40.1316 409.693 40.7598C409.952 41.3879 410.077 42.0638 410.058 42.7433V167.696C410.058 174.6 410.034 180.717 409.985 186.045C409.937 191.374 409.771 195.568 409.486 198.628C409.429 200.428 409.069 202.206 408.422 203.886C408.138 204.574 407.677 205.174 407.088 205.627C406.498 206.08 405.8 206.37 405.063 206.467L404.676 206.54L404.652 208.992Z"
            fill="black"
          />
          <path
            className="right-side"
            ref={(el) => (rightSideRef = el)}
            d="M404.596 208.992H384.937C383.785 209.04 383.221 208.661 383.237 207.863C383.237 207.363 383.487 207.056 384.083 207.008C384.625 206.998 385.164 206.939 385.694 206.83C386.238 206.728 386.776 206.596 387.306 206.435C387.858 206.227 388.347 205.88 388.727 205.428C389.106 204.976 389.363 204.434 389.473 203.854C389.947 202.135 390.249 200.374 390.375 198.595C390.628 195.547 390.781 191.352 390.835 186.013C390.888 180.674 390.91 174.568 390.899 167.696V40.4284C385.678 40.5172 339.449 40.6139 334.228 40.7027C331.005 40.7403 328.588 41.0898 326.977 41.7512C325.375 42.4128 324.094 43.6746 323.408 45.2678C322.836 46.4454 322.433 47.3649 322.183 48.0101C321.934 48.6554 321.732 49.2119 321.611 49.6878C321.289 50.7444 320.935 51.2041 320.532 51.1073C320.129 51.0105 320 50.6798 320 49.8249C320.081 48.6634 320.443 46.2222 321.088 42.5013C321.724 38.775 322.143 36.5085 322.328 35.6859C322.441 35.2019 322.586 34.6535 322.78 34.0243C322.973 33.3952 323.174 33.0565 323.392 33C323.937 33.1477 324.455 33.3848 324.923 33.7017C325.896 34.1824 326.972 34.4151 328.057 34.3792C329.668 34.5889 331.279 34.7664 332.891 34.8874C334.502 35.0083 335.686 35.0809 336.395 35.089H402.606C403.411 35.089 403.758 36.5005 403.089 37.1296L401.01 39.0976C400.544 39.5804 400.179 40.1517 399.937 40.7778C399.695 41.4039 399.581 42.0722 399.6 42.7433V167.696C399.6 174.6 399.622 180.717 399.665 186.045C399.708 191.374 399.864 195.568 400.132 198.628C400.183 200.423 400.52 202.198 401.131 203.886C401.38 204.558 401.803 205.151 402.357 205.605C402.91 206.058 403.575 206.356 404.281 206.467L404.571 206.524L404.596 208.992Z"
            fill="black"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="799.121"
              y1="118"
              x2="1.34092"
              y2="125.838"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.479167" stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="770.121"
              y1="95"
              x2="-27.6591"
              y2="102.838"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.479167" stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <clipPath id="clip0">
              <rect
                width="736.4"
                height="255.82"
                fill="white"
                transform="translate(65)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
}
