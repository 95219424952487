import React, { useEffect, useRef } from "react";
import "./braceletsCatrinel.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import DeskBg from "./images/braceletsCatrinel.webp";
import Video2 from "./images/video_2.mp4";

export default function BraceletsCatrinel() {
  return (
    <div className="braceletsCatrinel-main">
      <div
        className="catrinel-bg bracelets-bg"
        style={{ backgroundImage: `url(${DeskBg})` }}
      >
        {/* <img className="background-city" src={DeskBg} /> */}
      </div>
      <div className="catrinel-video-container">
        <video
          className=" catrinel-main-video"
          playsinline="true"
          autoplay="true"
          loop="true"
          muted="true"
        >
          <source src={Video2} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
