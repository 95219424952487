import React, { useEffect, useRef } from "react";
import "./ringsCatrinel.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Separator from "../../../images/separator.svg";

import Video2 from "./images/video_2.mp4";
import Placeholder from "./images/video-min.webp";
import Play from "./images/play.svg";

import { Player } from "video-react";
export default function RingsCatrinel() {
  gsap.registerPlugin(ScrollTrigger);
  let vidRef = useRef(null);
  useEffect(() => {
    vidRef.setAttribute("playsinline", "true");
  });
  return (
    <>
      <div className="ringsCatrinel-main">
        <div
          className="catrinel-video"
          style={{ backgroundImage: `url(${Placeholder})` }}
        >
          <video
            ref={(el) => (vidRef = el)}
            // playsInline="true"
            controls="true"
            className="ringsCatrinel-bg"
          >
            <source src={Video2} type="video/mp4" poster={Placeholder} />
          </video>
        </div>
        <div className="component-content rings-component-content">
          <div className="component-content-text">
            <p>
              {" "}
              Kézzel megmunkált, természetes és értékes drágakövekkel díszített,
              rendkívüli fényességgel és csillogással. A Pure Diamonds kollekció
              a meghódított világot ábrázolja.{" "}
            </p>
          </div>
          <div className="component-content-title rings-component-title">
            <p>... mindörökké</p>
          </div>
        </div>
      </div>
      <div className="ringsCatrinel-separator">
        <img src={Separator}></img>
      </div>
    </>
  );
}
