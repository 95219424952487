import "./styles.css";
import Footer from "./footer.js";
import "./slick-variable.css";
import "./slick-theme.min.css";
import "./slick.min.css";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";

import PureDiamonds from "./pureDiamonds/pureDiamonds";
import LogoAnim from "./logoAnim/logoAnim";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from "react-scroll";

export default function App() {
  //let appDelay = useRef(null);
  let appDelay = useRef(null);
  let logoAnimRef = useRef(null);
  let appBody = useRef(null);
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  // useEffect(() => {
  //   scroll.scrollToTop();
  // });
  useEffect(() => {
    gsap.from(appDelay, {
      //display: "none",
      opacity: 0,
      duration: 1.4,
      delay: 5.5
    });
    gsap.to(appBody, {
      duration: 1.4,
      // overflowY: "visible",

      delay: 5.5
    });
    gsap.to(logoAnimRef, {
      display: "none",
      duration: 3.0,
      delay: 2
    });
  });
  return (
    <>
      <div ref={(el) => (logoAnimRef = el)}>
        <LogoAnim />
      </div>
      <div className="mainAppBody" ref={(el) => (appBody = el)}>
        <div className="faded" ref={(el) => (appDelay = el)}>
          {/* <Menu /> */}
          <PureDiamonds />
          <Footer />
        </div>
      </div>
    </>
  );
}
