import React from "react";
import ReactDOM from "react-dom";
import "./slick-variable.css";
import "./slick-theme.min.css";
import "./slick.min.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";

import App from "./app";

const rootElement = document.getElementById("root");

const browserRouter = (
  <BrowserRouter>
    <div>
      <Switch>
        <Route path="/" exact>
          <App />
        </Route>
      </Switch>
    </div>
  </BrowserRouter>
);

ReactDOM.render(browserRouter, rootElement);
