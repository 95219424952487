import React, { useEffect, useRef } from "react";
import "./headMain.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import logo from "../../images/teilor-logo.svg";
import Background from "./images/bg.webp";
import Separator from "../../images/separator.svg";

export default function HeadMain() {
  gsap.registerPlugin(ScrollTrigger);

  let bgRef = useRef(null);
  let sepRef = useRef(null);
  let titleRef = useRef(null);

  useEffect(() => {
    gsap.to(bgRef, {
      opacity: 0,
      // y:-65,
      scrollTrigger: {
        // start: "100px",

        start: "bottom bottom",
        trigger: sepRef,
        // markers: true,
        scrub: 1
      }
    });
  });
  useEffect(() => {
    gsap.to(bgRef, {
      backgroundPositionY: -500,
      scrollTrigger: {
        start: "100px",

        // markers: true,
        scrub: 1
      }
    });
  });

  useEffect(() => {
    gsap.to(bgRef, {
      opacity: 1,
      // y:-65,
      delay: 6
    });
  });
  return (
    <>
      <div className="headMain-header" ref={(el) => (titleRef = el)}>
        <img src={logo}></img>
      </div>
      <div className="headMain-main">
        <div
          className="headMain-bg"
          style={{ backgroundImage: `url(${Background})` }}
          ref={(el) => (bgRef = el)}
        ></div>

        <div className="head-content">
          <div className="head-title">
            <span>
              Tiszta
              <br />
              gyémántok
            </span>
          </div>
          <div className="head-second-title">
            <span>Modern nosztalgia</span>
          </div>
          <div className="head-text">
            <p>
              amely elrejti emlékeit, miközben megosztja azokat a világ többi
              részével.
            </p>
          </div>
        </div>
        <div className="head-effect"></div>
      </div>
      <div className="headMain-separator">
        <img src={Separator}></img>
      </div>
      <div className="pseudo-div" ref={(el) => (sepRef = el)}></div>
    </>
  );
}
