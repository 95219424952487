import React, { useEffect, useRef } from "react";
import "./necklacesShop.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ShopIcon from "../../../images/shop.svg";
import Necklace_1 from "./images/necklace_1.webp";
import Necklace_2 from "./images/necklace_2.webp";
import Necklace_3 from "./images/necklace_3.webp";
import Necklace_4 from "./images/necklace_4.webp";
import Necklace_5 from "./images/necklace_5.webp";
import Video1 from "./images/video_1.mp4";

export default function NecklacesShop() {
  return (
    <div className="necklacesShop-main">
      <div className="necklacesShop-container">
        <video
          playsinline="true"
          autoplay="true"
          loop="true"
          muted="true"
          className="necklacesShop-element necklacesShop-video"
        >
          <source src={Video1} type="video/mp4" />
        </video>
        <div className="necklacesShop-element necklacesShop-product-1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-necklace-with-diamond-of-0-3ct-106435.html"
          >
            <img className="zoom" alt="" src={Necklace_1}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
        <div className="necklacesShop-element necklacesShop-product-2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-chain-with-pendant-with-diamonds-of-0-2-ct-51783.html"
          >
            <img className="zoom" alt="" src={Necklace_2}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
        <div className="necklacesShop-element necklacesShop-product-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/14k-yellow-gold-necklace-with-diamonds-of-0-5ct-36714.html"
          >
            <img className="zoom" alt="" src={Necklace_3}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
        <div className="necklacesShop-element necklacesShop-product-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-necklace-with-sapphire-of-2-46ct-and-diamond-of-0-3ct-55872.html"
          >
            <img className="zoom" alt="" src={Necklace_4}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
        <div className="necklacesShop-element necklacesShop-product-5">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-chain-with-pendant-with-diamonds-of-0-12ct-54207.html"
          >
            <img className="zoom" alt="" src={Necklace_5}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
      </div>
      <div className="component-button">
        <div className="component-button-border"></div>
        <div className="component-button-text">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/pure-diamonds/filtre/type-of-jewellery-necklace/type-of-jewellery-necklace-with-pendants/type-of-jewellery-pendants.html"
          >
            ékszereknek nevezzük őket
          </a>
        </div>
      </div>
    </div>
  );
}
