import React, { useEffect, useRef } from "react";
import "./necklacesCatrinel.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import DeskBg from "./images/necklacesCatrinel.webp";
import Video2 from "./images/video_2.mp4";

export default function NecklacesCatrinel() {
  return (
    <div className="necklacesCatrinel-main">
      <div
        className="catrinel-bg necklaces-bg"
        style={{ backgroundImage: `url(${DeskBg})` }}
      ></div>
      <div className="catrinel-video-container">
        <video
          className=" catrinel-main-video"
          playsinline="true"
          autoplay="true"
          loop="true"
          muted="true"
        >
          <source src={Video2} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
