import React, { useEffect, useRef } from "react";
import "./ringsShop.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Ring_1 from "./images/ring_1.webp";
import Ring_2 from "./images/ring_2.webp";
import Ring_3 from "./images/ring_3.webp";
import Ring_4 from "./images/ring_4.webp";
import Ring_5 from "./images/ring_5.webp";
import ShopIcon from "../../../images/shop.svg";

import Video1 from "./images/video_1.mp4";

export default function RingsShop() {
  return (
    <div className="ringsShop-main">
      <div className="ringsShop-container">
        <video
          playsinline="true"
          autoplay="true"
          loop="true"
          muted="true"
          className="ringsShop-element ringsShop-video"
        >
          <source src={Video1} type="video/mp4" />
        </video>
        <div className="ringsShop-element ringsShop-product-1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-ring-with-diamonds-of-0-36ct-21852.html"
          >
            <img className="zoom" src={Ring_1} alt=""></img>
            <img className="shop-icon" src={ShopIcon} alt=""></img>
          </a>
        </div>
        <div className="ringsShop-element ringsShop-product-2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-ring-with-diamonds-of-1-066-32821.html"
          >
            <img className="zoom" src={Ring_2} alt=""></img>
            <img className="shop-icon" src={ShopIcon} alt=""></img>
          </a>
        </div>
        <div className="ringsShop-element ringsShop-product-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-ring-with-diamonds-of-0-29ct-105037.html"
          >
            <img className="zoom" src={Ring_3} alt=""></img>
            <img className="shop-icon" src={ShopIcon} alt=""></img>
          </a>
        </div>
        <div className="ringsShop-element ringsShop-product-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-yellow-gold-ring-with-yellow-diamonds-of-0-85ct-54073.html"
          >
            <img className="zoom" src={Ring_4} alt=""></img>
            <img className="shop-icon" src={ShopIcon} alt=""></img>
          </a>
        </div>
        <div className="ringsShop-element ringsShop-product-5">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/14k-white-gold-ring-with-sapphires-of-4-81ct-and-diamonds-of-0-53ct-24107.html"
          >
            <img className="zoom" src={Ring_5} alt=""></img>
            <img className="shop-icon" src={ShopIcon} alt=""></img>
          </a>
        </div>
      </div>
      <div className="component-button">
        <div className="component-button-border"></div>
        <div className="component-button-text">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/pure-diamonds/filtre/type-of-jewellery-rings/type-of-jewellery-engagement-rings.html"
          >
            Fedezze fel a gyűrűket
          </a>
        </div>
      </div>
    </div>
  );
}
