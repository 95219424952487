import React, { useEffect, useRef } from "react";
import "./earringsShop.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Earring_1 from "./images/earrings_1.webp";
import Earring_2 from "./images/earrings_2.webp";
import Earring_3 from "./images/earrings_3.webp";
import Earring_4 from "./images/earrings_4.webp";
import Earring_5 from "./images/earrings_5.webp";
import Video1 from "./images/video_1.mp4";
import ShopIcon from "../../../images/shop.svg";
export default function earringsShop() {
  return (
    <div className="earringsShop-main">
      <div className="earringsShop-container">
        {/* <div className="earringsShop-element earringsShop-video"></div> */}
        <video
          playsinline="true"
          autoplay="true"
          loop="true"
          muted="true"
          className="earringsShop-element earringsShop-video"
        >
          <source src={Video1} type="video/mp4" />
        </video>
        <div className="earringsShop-element earringsShop-product-1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-earrings-with-diamonds-of-0-49ct-10893.html"
          >
            <img className="zoom" alt="" src={Earring_1}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
        <div className="earringsShop-element earringsShop-product-2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-earrings-with-diamonds-of-0-776ct-105782.html"
          >
            <img className="zoom" alt="" src={Earring_2}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
        <div className="earringsShop-element earringsShop-product-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-yellow-earrings-with-coloured-diamonds-of-2-86ct-and-transparent-diamonds-of-1-81ct-108241.html"
          >
            <img className="zoom" alt="" src={Earring_3}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
        <div className="earringsShop-element earringsShop-product-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-earrings-with-blue-diamonds-of-0-66ct-and-diamonds-of-0-07ct-53721.html"
          >
            <img className="zoom" alt="" src={Earring_4}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
        <div className="earringsShop-element earringsShop-product-5">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/14k-white-gold-earrings-with-diamonds-of-0-51ct-17588.html"
          >
            <img className="zoom" alt="" src={Earring_5}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
      </div>
      <div className="component-button">
        <div className="component-button-border"></div>
        <div className="component-button-text">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/pure-diamonds/filtre/type-of-jewellery-earrings.html"
          >
            Fedezd fel a fülbevalókat
          </a>
        </div>
      </div>
    </div>
  );
}
