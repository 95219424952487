import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from "react-scroll";

import "../styles.css";
import "./pureDiamonds.css";
import "./shopsMain.css";
import "./menuCssPortrait.css";
import "./menuCssDesktop.css";

import MenuSep from "./menu-sep.svg";
import MenuImg1 from "./menuImgs/ring.webp";
import MenuImg2 from "./menuImgs/bracelets.webp";
import MenuImg3 from "./menuImgs/earrings.webp";

import MenuImg4 from "./menuImgs/necklace.webp";

import PortraitLogo from "./menuImagesPortrait/portrait-logo.svg";
import Menu from "./menuImagesPortrait/menu.svg";
import Closed from "./menuImagesPortrait/closed.svg";
import JwlImg1 from "./menuImagesPortrait/jwl-1.png";
import JwlImg2 from "./menuImagesPortrait/jwl-2.png";
import JwlImg3 from "./menuImagesPortrait/jwl-3.png";
import JwlImg4 from "./menuImagesPortrait/jwl-4.png";

import gsap from "gsap";
import { TimelineLite, Power2 } from "gsap";

import Earrings from "./earringsMain/earrings/earrings";
import EarringsShop from "./earringsMain/earringsShop/earringsShop";
import EarringsCatrinel from "./earringsMain/earringsCatrinel/earringsCatrinel";

import Necklaces from "./necklacesMain/necklaces/necklaces";
import NecklacesShop from "./necklacesMain/necklacesShop/necklacesShop";
import NecklacesCatrinel from "./necklacesMain/necklacesCatrinel/necklacesCatrinel";

import Bracelets from "./braceletsMain/bracelets/bracelets";
import BraceletsShop from "./braceletsMain/braceletsShop/braceletsShop";
import BraceletsCatrinel from "./braceletsMain/braceletsCatrinel/braceletsCatrinel";

import Rings from "./ringsMain/rings/rings";
import RingsShop from "./ringsMain/ringsShop/ringsShop";
import RingsCatrinel from "./ringsMain/ringsCatrinel/ringsCatrinel";

import Club from "./club/club";
import Beyond from "./beyond/beyond";

import HeadMain from "./headMain/headMain";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

export default function PureDiamonds() {
  gsap.registerPlugin(ScrollToPlugin);

  let timeline = new TimelineLite();
  let bottomMenu2Ref = useRef(null);
  // let viewRef = useRef(null);
  let jwlUlRef = useRef(null);
  let menuIconRef = useRef(null);
  let closedIconRef = useRef(null);
  let menuMainStaggerRef = useRef([]);
  let rightRef1 = useRef(null);
  let rightRef2 = useRef(null);
  let leftRef1 = useRef(null);
  let leftRef2 = useRef(null);

  let earringsRef = useRef(null);
  let necklacesRef = useRef(null);
  let braceletsRef = useRef(null);
  let ringsRef = useRef(null);

  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  function scrollToTop() {
    scroll.scrollToTop();
  }
  function scrollTo() {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  }
  function scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register("end", () => {
        resolve();
        Events.scrollEvent.remove("end");
      });

      scroller.scrollTo("scroll-container", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart"
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo("scroll-container-second-element", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "scroll-container"
      })
    );
  }
  useEffect(() => {
    gsap.set(bottomMenu2Ref, {
      height: 0
    });
    gsap.set(rightRef1, {
      x: 50,
      opacity: 0
    });
    gsap.set(rightRef2, {
      x: 50,
      opacity: 0
    });
    gsap.set(leftRef1, {
      x: -50,
      opacity: 0
    });
    gsap.set(leftRef2, {
      x: -50,
      opacity: 0
    });
  });

  function menuShow(boolean) {
    if (boolean) {
      // document.body.style.overflowY = "hidden !important";
      document
        .getElementById("body-main")
        .setAttribute("style", "overflow-y: hidden !important;");
      document
        .getElementById("html-main")
        .setAttribute("style", "overflow-y: hidden !important;");
      // setCheck((check) => !check);
      gsap.to(rightRef1, {
        x: 0,
        opacity: 1,
        duration: 1.0
      });
      gsap.to(rightRef2, {
        x: 0,
        opacity: 1,
        duration: 1.0
      });
      gsap.to(leftRef1, {
        x: 0,
        opacity: 1,
        duration: 1.0
      });
      gsap.to(leftRef2, {
        x: 0,
        opacity: 1,
        duration: 1.0
      });
      gsap.to(bottomMenu2Ref, {
        opacity: 1,
        height: "100%",
        display: "block",

        duration: 0.6
      });
      gsap.to(menuIconRef, {
        scale: 0,
        opacity: 0,
        display: "none",
        duration: 0.5
      });
      gsap.to(closedIconRef, {
        scale: 1,
        rotation: 180,
        opacity: 1,
        display: "block",
        duration: 0.5
      });
      timeline.staggerFrom(
        menuMainStaggerRef.current,
        1.0,
        { x: 50, opacity: 0, ease: Power2.easeInOut },
        0.2
      );
    } else {
      // [jwlUlRef].forEach((context) => closedMenu(context));
      closedMenu(jwlUlRef);
      // document.body.style.overflowY = "unset";
      document
        .getElementById("body-main")
        .setAttribute("style", "overflow-y: unset !important;");
      document
        .getElementById("html-main")
        .setAttribute("style", "overflow-y: unset !important;");

      gsap.to(rightRef1, {
        x: 50,
        opacity: 0,
        duration: 1.0
      });
      gsap.to(rightRef2, {
        x: 50,
        opacity: 0,
        duration: 1.0
      });
      gsap.to(leftRef1, {
        x: -50,
        opacity: 0,
        duration: 1.0
      });
      gsap.to(leftRef2, {
        x: -50,
        opacity: 0,
        duration: 1.0
      });

      gsap.to(bottomMenu2Ref, {
        opacity: 0,
        height: 0,
        display: "none",
        duration: 0.6
        // delay: 0.6
      });
      gsap.to(menuIconRef, {
        scale: 1,
        opacity: 1,
        display: "block",
        duration: 0.5
      });

      gsap.to(closedIconRef, {
        scale: 0,
        rotation: 0,
        opacity: 0,
        display: "none",
        duration: 0.5
      });
    }
  }
  function closedMenu(ref) {
    // gsap.to(viewRef, {
    //   x: 400,
    //   opacity: 0,
    //   duration: 0.6,
    //   display: "none"
    // });
    // gsap.to(ref, {
    //   x: 400,
    //   stagger: 0.5,
    //   display: "none",
    //   duration: 1.3,
    //   opacity: 0
    // });
  }
  return (
    <>
      <div className="portrait-navbar">
        <div className="bottom-menu-2" ref={(el) => (bottomMenu2Ref = el)}>
          <ul className="jwl-ul" ref={(el) => (jwlUlRef = el)}>
            <li
              ref={(el) => {
                leftRef1 = el;
              }}
            >
              <div>
                <Link
                  activeClass="active"
                  to="rings"
                  onClick={() => menuShow(false)}
                >
                  <img src={MenuImg1}></img>
                  <div className="button-text-menu">
                    <div className="button-line-menu line-right"></div>

                    <p>
                      <span>gyűrűk</span>
                    </p>
                  </div>
                </Link>
              </div>
            </li>
            <li
              ref={(el) => {
                rightRef1 = el;
              }}
            >
              <div className="bracelets-portrait-positioning">
                <Link
                  activeClass="active"
                  to="bracelets"
                  onClick={() => menuShow(false)}
                >
                  <img src={MenuImg2}></img>
                  <div className="button-text-menu">
                    <div className="button-line-menu line-left"></div>

                    <p>
                      <span>karkötők</span>
                    </p>
                  </div>
                </Link>
              </div>
            </li>
            <li
              ref={(el) => {
                leftRef2 = el;
              }}
            >
              <div className="earrings-portrait-positioning">
                <Link
                  activeClass="active"
                  to="earrings"
                  onClick={() => menuShow(false)}
                >
                  <img src={MenuImg3}></img>
                  <div className="button-text-menu">
                    <div className="button-line-menu line-right"></div>

                    <p>
                      <span>fülbevalók</span>
                    </p>
                  </div>
                </Link>
              </div>
            </li>
            <li
              ref={(el) => {
                rightRef2 = el;
              }}
            >
              <div>
                <Link
                  activeClass="active"
                  to="necklaces"
                  onClick={() => menuShow(false)}
                >
                  <img src={MenuImg4}></img>
                  <div className="button-text-menu">
                    <div className="button-line-menu line-left"></div>
                    <p>
                      <span>nyakláncok </span>
                    </p>
                  </div>
                </Link>
              </div>
            </li>
          </ul>
        </div>

        <div className="portrait-navbar-main">
          <div className="bottom-items-left">
            <div className="left-item">
              <img className="bottom-title" src={PortraitLogo} />
            </div>
          </div>
          <ul className="bottom-items-right ">
            <li className="right-item">
              <div
                className="menu-portrait-div"
                onClick={() => menuShow(true)}
                ref={(el) => (menuIconRef = el)}
              >
                <img
                  className="normal-menu"
                  src={MenuSep}
                  // onClick={() => menuShow(true)}
                  // ref={(el) => (menuIconRef = el)}
                  alt=""
                />
              </div>
              {/* <div
                className="closed-portrait-div"
                onClick={() => menuShow(false)}
                ref={(el) => (closedIconRef = el)}
              > */}
              <img
                className="closed-menu"
                src={Closed}
                onClick={() => menuShow(false)}
                ref={(el) => (closedIconRef = el)}
                alt=""
              />
              {/* </div> */}
            </li>
          </ul>
        </div>
      </div>
      <div className="desktop-navbar">
        <div className="menu">
          <ul className="menu-left">
            <li className="menu-item">
              <button
                className="dropbtn"
                // ref={addRef}
              >
                <Link
                  activeClass="active"
                  to="rings"
                  spy={true}
                  smooth={true}
                  duration={1000}
                >
                  gyűrűk
                </Link>
              </button>
            </li>
            <li className="menu-separator">
              <img src={MenuSep} alt=""></img>
            </li>
            <li className="menu-item">
              <button className="dropbtn">
                <Link
                  activeClass="active"
                  to="bracelets"
                  spy={true}
                  smooth={true}
                  duration={1000}
                >
                  karkötők
                </Link>
              </button>
            </li>
          </ul>
          <ul className="menu-right">
            <li className="menu-item">
              <button
                className="dropbtn"
                // ref={addRef}
              >
                <Link
                  activeClass="active"
                  to="earrings"
                  spy={true}
                  smooth={true}
                  duration={1000}
                >
                  fülbevalók
                </Link>
              </button>
            </li>
            <li className="menu-separator">
              <img src={MenuSep} alt=""></img>
            </li>
            <li className="menu-item">
              <button
                className="dropbtn"
                // ref={addRef}
              >
                <Link
                  activeClass="active"
                  to="necklaces"
                  spy={true}
                  smooth={true}
                  duration={1000}
                >
                  nyakláncok
                </Link>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="headMain-link">
        <a
          className="teilor-main-link-bottom"
          href={"https://www.teilor.com/"}
        ></a>
      </div>
      <div>
        <div id="one"></div>
        <HeadMain />
      </div>

      <div className="bg-div">
        <div>
          <div
            className="div-ref-scroll"
            ref={(el) => (earringsRef = el)}
            id="earrings"
          ></div>
          <Earrings />
        </div>
        <EarringsShop />
        <EarringsCatrinel />

        <div>
          <div
            className="div-ref-scroll neck-scroll"
            ref={(el) => (necklacesRef = el)}
            id="necklaces"
          ></div>
          <Necklaces />
        </div>
        <NecklacesShop />
        <NecklacesCatrinel />

        <div>
          <div
            className="div-ref-scroll"
            ref={(el) => (braceletsRef = el)}
            id="bracelets"
          ></div>
          <Bracelets />
        </div>
        <BraceletsShop />
        <BraceletsCatrinel />

        <div>
          <div
            className="div-ref-scroll"
            ref={(el) => (ringsRef = el)}
            id="rings"
          ></div>
          <Rings />
        </div>
        <RingsShop />
        <RingsCatrinel />
        <Club />
        <Beyond />
      </div>
    </>
  );
}
