import React, { useEffect, useRef } from "react";
import "./earringsCatrinel.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import DeskBg from "./images/earringsCatrinel.webp";
import Video2 from "./images/video_2.mp4";
import "../../../slick-variable.css";
import "../../../slick-theme.min.css";
import "../../../slick.min.css";

export default function EarringsCatrinel() {
  gsap.registerPlugin(ScrollTrigger);

  let videoTriggerRef = useRef(null);
  let videoRef = useRef(null);
  // useEffect(() => {
  //   videoRef.removeAttribute("autoplay");
  //   videoRef.removeAttribute("playsinline");
  //   videoRef.removeAttribute("loop");
  // });

  // useEffect(() => {
  //   gsap.to(videoRef, {
  //     scrollTrigger: {
  //       start: "bottom bottom",
  //       // markers: true,
  //       trigger: videoTriggerRef,
  //       onToggle: (self) =>
  //         self.isActive
  //           ? videoRef.setAttribute("autoplay", "true")
  //           : videoRef.removeAttribute("autoplay")
  //     }
  //   });
  // });

  return (
    <div className="earringsCatrinel-main" ref={(el) => (videoTriggerRef = el)}>
      <div
        className="catrinel-bg earrings-bg"
        style={{ backgroundImage: `url(${DeskBg})` }}
      ></div>
      <div className="catrinel-video-container">
        <video
          ref={(el) => (videoRef = el)}
          className="catrinel-main-video"
          playsinline="true"
          autoplay="true"
          loop="true"
          muted="true"
        >
          <source src={Video2} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
