import React, { useEffect, useRef } from "react";
import "./necklaces.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import imgLeft1 from "./images/layerLeft1.webp";
import imgLeft2 from "./images/layerLeft2.webp";
import imgRight1 from "./images/layerRight1.webp";
import imgRight2 from "./images/layerRight2.webp";
import videoNecklace from "./images/Colier.mp4";
export default function Necklaces() {
  gsap.registerPlugin(ScrollTrigger);

  let col1Ref = useRef(null);
  let col2Ref = useRef(null);
  let col3Ref = useRef(null);
  let col4Ref = useRef(null);
  let titleRef = useRef(null);

  return (
    <div className="necklaces-main">
      <div className="component-header" ref={(el) => (titleRef = el)}>
        <p>nyakláncok</p>
      </div>
      <div className="component-images">
        <div className="component-column column-left">
          <div className="left-content">
            <div className="left-image-1" ref={(el) => (col2Ref = el)}>
              <img src={imgLeft2}></img>
            </div>
            <div className="left-image-2" ref={(el) => (col1Ref = el)}>
              <img src={imgLeft1}></img>
            </div>
          </div>
        </div>
        <div className="component-column column-middle">
          <div className="middle-content">
            <div className="middle-image"></div>
            <div className="middle-video necklace-video">
              <video
                className="middle-video"
                playsinline="true"
                autoplay="true"
                loop="true"
                muted="true"
              >
                <source src={videoNecklace} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        <div
          className="component-column column-right"
          ref={(el) => (col3Ref = el)}
        >
          <div className="right-content">
            <div className="right-image-1" ref={(el) => (col3Ref = el)}>
              <img src={imgRight1}></img>
            </div>
            <div className="right-image-2" ref={(el) => (col4Ref = el)}>
              <img src={imgRight2}></img>
            </div>
          </div>
        </div>
      </div>
      <div className="component-content">
        <div className="component-content-title">
          <p>kiegészítve </p>
        </div>
        <div className="component-content-text">
          <p>
            {" "}
            azt, ami már megvan.
            <br />
            Vannak, akik nyakékeknek hívják. Mások függőknek.
            <br />
            Mi kifinomult ékszereknek nevezzük őket.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
