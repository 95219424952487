import React, { useEffect, useRef } from "react";
import "./braceletsShop.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Bracelet_1 from "./images/bracelet_1.webp";
import Bracelet_2 from "./images/bracelet_2.webp";
import Bracelet_3 from "./images/bracelet_3.webp";
import Bracelet_4 from "./images/bracelet_4.webp";
import Bracelet_5 from "./images/bracelet_5.webp";
import ShopIcon from "../../../images/shop.svg";
import Video1 from "./images/video_1.mp4";

export default function BraceletsShop() {
  return (
    <div className="braceletsShop-main">
      <div className="braceletsShop-container">
        <video
          playsinline="true"
          autoplay="true"
          loop="true"
          muted="true"
          className="braceletsShop-element braceletsShop-video"
        >
          <source src={Video1} type="video/mp4" />
        </video>
        <div className="braceletsShop-element braceletsShop-product-1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-tennis-bracelet-with-diamonds-of-0-49ct-52311.html"
          >
            <img className="zoom" alt="" src={Bracelet_1}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
        <div className="braceletsShop-element braceletsShop-product-2">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-bracelet-with-diamonds-of-1-48ct-53685.html"
          >
            <img className="zoom" alt="" src={Bracelet_2}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
        <div className="braceletsShop-element braceletsShop-product-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-bracelet-with-diamonds-of-0-87ct-112562.html"
          >
            <img className="zoom" alt="" src={Bracelet_3}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
        <div className="braceletsShop-element braceletsShop-product-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-tennis-bracelet-with-blue-diamonds-of-1-9ct-108292.html"
          >
            <img className="zoom" alt="" src={Bracelet_4}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
        <div className="braceletsShop-element braceletsShop-product-5">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/18k-white-gold-tennis-bracelet-with-black-diamonds-of-12ct-9260.html"
          >
            <img className="zoom" alt="" src={Bracelet_5}></img>
            <img className="shop-icon" alt="" src={ShopIcon}></img>
          </a>
        </div>
      </div>
      <div className="component-button">
        <div className="component-button-border"></div>
        <div className="component-button-text">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teilor.com/pure-diamonds/filtre/type-of-jewellery-bracelets/type-of-jewellery-tennis-bracelets.html"
          >
            Fedezze fel a karkötőket
          </a>
        </div>
      </div>
    </div>
  );
}
