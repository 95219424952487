import React, { useEffect, useState, useRef } from "react";
import "./club.css";

import logo from "../../images/teilor-logo.svg";
import Layer from "./images/row-layer.svg";

export default function Club() {
  return (
    <div className="clubMain-content">
      <div
        className="content-section-1"
        style={{ backgroundImage: `url(${Layer})` }}
      >
        <div className="section-1-logo">
          <img src={logo} alt="" />
        </div>
        <div className="section-1-title">Klub</div>
        <div className="section-1-container">
          <div className="section-1-col-1"></div>
          <div className="section-1-col-2">
            <div className="col-2-separator">
              <div className="css-separator"></div>
            </div>
            <div className="col-2-text">
              <a href="https://club.teilor.hu/">
                csatlakozik
                <i className="arrow right"></i>
              </a>
            </div>
            <div className="col-2-separator">
              <div className="css-separator"></div>
            </div>
          </div>
          <div className="section-1-col-3"></div>
        </div>
      </div>
    </div>
  );
}
